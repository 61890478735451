import { PUBLIC_ENVIRONMENT, PUBLIC_SENTRY_WEBAPP_DNS } from '$env/static/public';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

let integrations;
if (PUBLIC_ENVIRONMENT === 'staging') {
	integrations = [
		replayIntegration(),
		Sentry.feedbackIntegration({
			// Additional SDK configuration goes in here, for example:
			colorScheme: 'system'
		})
	];
} else {
	integrations = [replayIntegration()];
}

Sentry.init({
	dsn: PUBLIC_SENTRY_WEBAPP_DNS,
	environment: PUBLIC_ENVIRONMENT,
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
